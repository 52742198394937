import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Taste of Rejection`}</h1>
    <p>{`Dear Blush,`}</p>
    <p>{`Yesterday - we walked through making our pitch deck. That's the ammo we need to
sell.`}</p>
    <p>{`But ammo is useless if we're afraid to pull the trigger.`}</p>
    <p>{`So why would we be afraid of pulling the trigger? Me? Your big bad dad? Afraid?
Psh...horse-swaggle.`}</p>
    <p>{`(Ok, fine. Yes - I feel fear. All the time.)`}</p>
    <p>{`My brain has this fucked up loop. "What if I get rejected?" → "then I will fail"
→ "if I fail...then...I'm a...failure?"`}</p>
    <p>{`Now let's be clear. It's not like I walk around thinking "wow I'm so afraid
right now". It's subconscious. It's the reason I do dumb things.`}</p>
    <p>{`Some people say they are fearless. I call bullshit. Fear never goes away. And
being fearless would be stupid from an evolutionary point of view (if there's a
lion, it might be good to be afraid) So Blushie what we want to do is learn how
to recognize fear, and learn to execute in spite of the fear. I call this
dancing with fear.`}</p>
    <p>{`Now - "recognizing fear" is not easy. Fear wears many disguises. Especially for
grownups.`}</p>
    <p>{`When you're a little kid, it's socially acceptable to be "afraid". But the older
you get, people will start pretending they are never afraid. They come up with
all kinds of fancy codewords for fear. Stress is grownups favorite codeword for
fear. The next one is "anxious". And some silly grownups have all kinds of
"superstitions" (no joke, they will wear the same dirty underwear on big
occasions because they think that's the secret to success)`}</p>
    <p>{`Anyways - back to the topic at hand. It would be really easy to spend today
polishing the pitch deck. Surely there are many ways we could improve it, and
make it better.`}</p>
    <p>{`But we won't do that. That's fear taking the wheel of the car. We keep fear tied
up in the trunk of the car. People are really afraid to go and try to sell,
because they are afraid of getting rejected.`}</p>
    <p><strong parentName="p">{`Here's how I deal with this:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`1. ACCEPT`}</strong>{` - that rejection is INEVITABLE in any sales process (and remember,
fundraising is sales)`}</p>
    <p><strong parentName="p">{`2. EXPECT`}</strong>{` - rejection to come. You see - most people only keep track of
their "yesses". I also keep track of all my "no"s. My scoreboard has both. And
when I create my sales funnel, I forecast how many `}<strong parentName="p">{`yes`}</strong>{` and how many `}<strong parentName="p">{`no`}</strong>{` I
need to hit my goal.`}</p>
    <p><strong parentName="p">{`For example: For this fundraise, here's my
`}<a parentName="strong" {...{
          "href": "https://docs.google.com/spreadsheets/d/1uhOejITxiIIvUsYRgY3SqPbpS1UUZ8XT9-exIn28hzE/edit?usp=sharing"
        }}>{`Rejection Calculator`}</a></strong><br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "501px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIUlEQVQY022QzUuFQBTF/bP7gGjVJlrVLmoTrSpo16KIilHUMUvRcdRniULvPbRQ0/T52cXpxYP6LS53mHPuvRxOlmVVVUVRZI0kSXEcF0WRjPR9X5alZVm6rhNCuq7rl1RVxWGMDcNgTkEQNE0DHahN04TK1GEY/m/meZ5S6rgOj5AsSVjBvu9nWZamKVSmbts2iiLXdaHpRn7MoIDD6rqGBq7N8xz+hhG2YVjSNM3qc7GoOCYaVmCD2Yjp7M20DOqQZ12NPyJ78kKcifKk257/+TWa//JrvqGX+3fbR8LuIdo5uN7butA3z+2NM7p+qq6dyBxE5XkeJBwEAUIIwlMUZT6fjyf0WZVk9bsTWPzjPcIP06SMi858nd3y8vEV+QYbLHkIUJ5pUAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Rejection Calculator",
          "title": "Rejection Calculator",
          "src": "/static/cf717e30c989245398a4a18c627dbfe3/56272/rejection-calculator.png",
          "srcSet": ["/static/cf717e30c989245398a4a18c627dbfe3/d9f49/rejection-calculator.png 400w", "/static/cf717e30c989245398a4a18c627dbfe3/56272/rejection-calculator.png 501w"],
          "sizes": "(max-width: 501px) 100vw, 501px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`As you can see - I am literally planning to hear "no" 313 times after giving my
pitch. And since a yes is worth
$32,000 to me, and I need to hear 10 no's to get a yes...each no is worth $3,200
to me. (don't worry if you're confused - this isn't actually legit math. This is
psychology!)`}</p>
    <p>{`So anytime I get a response, I tally which side it's on. Was this a
$3,200 answer? Or a $32,000 answer. Either way, I'm winning.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Next - I start selling.`}</strong></p>
    <p>{`With selling, I like to start with the layups. My hot leads. These are people
who came inbound from when I mentioned I would do this on the podcast. It's not
a lot (19 folks) but it's time to send them the pitch deck and see how they
react.`}</p>
    <p>{`Here's an example of reaching back out to one of my hot leads (they reached out
themselves to say they are interested)...that ended up in a `}<strong parentName="p">{`No`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1242px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "216.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfKIJVF0kzC3lscoP//EABsQAAICAwEAAAAAAAAAAAAAAAARARACIDEh/9oACAEBAAEFAmMdOkLWOVgZR5Pf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwFf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwFf/8QAFxABAQEBAAAAAAAAAAAAAAAAMRAgAP/aAAgBAQAGPwKnGimf/8QAGxAAAwACAwAAAAAAAAAAAAAAAAEREFEhMWH/2gAIAQEAAT8hMDFEaDJhI1GKaZFsPs8MH2cml6IXBYP/2gAMAwEAAgADAAAAEBgowjjv/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxBf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxBf/8QAHxABAAICAgIDAAAAAAAAAAAAAQARITFRYRBxQZHw/9oACAEBAAE/EEIGaNQ4m/cFbplAydeIstoHqWDevid79zEDcFDh7im0lTATqEQtpR+ENIgstKluobjbZn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fund Rejection Email",
          "title": "Fund Rejection Email",
          "src": "/static/a6cac2452a745182e5a18af98046df25/ff5f8/fund-rejection-email.jpg",
          "srcSet": ["/static/a6cac2452a745182e5a18af98046df25/a6407/fund-rejection-email.jpg 400w", "/static/a6cac2452a745182e5a18af98046df25/a24e6/fund-rejection-email.jpg 800w", "/static/a6cac2452a745182e5a18af98046df25/ff5f8/fund-rejection-email.jpg 1242w"],
          "sizes": "(max-width: 1242px) 100vw, 1242px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1242px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "216.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHluWIgNXDIAGzUwEH/xAAbEAACAgMBAAAAAAAAAAAAAAAAARAxAhEhQf/aAAgBAQABBQK4Z5CoYjcLZ2Mby4j/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AV//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AV//xAAUEAEAAAAAAAAAAAAAAAAAAABA/9oACAEBAAY/Ahf/xAAbEAACAwEBAQAAAAAAAAAAAAAAAREhMRBBcf/aAAgBAQABPyFUIGsSED5M0NCw+RuWUUQ45MV4Lauf/9oADAMBAAIAAwAAABDr577I7//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QX//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QX//EACEQAQACAgEDBQAAAAAAAAAAAAEAESExYRBBUYGRobHh/9oACAEBAAE/ELBWjRNeYIx+4yE1ehi6cJ6/swd3zPP5gGrLri5RdFcRa8u+yNDPsSZiqgeXEdx+ZQ0rZzGq1s6h5UbO/T//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fund Rejection Email 2",
          "title": "Fund Rejection Email 2",
          "src": "/static/005fc0efa9be901086f04edbdef8d806/ff5f8/fund-rejection-email-2.jpg",
          "srcSet": ["/static/005fc0efa9be901086f04edbdef8d806/a6407/fund-rejection-email-2.jpg 400w", "/static/005fc0efa9be901086f04edbdef8d806/a24e6/fund-rejection-email-2.jpg 800w", "/static/005fc0efa9be901086f04edbdef8d806/ff5f8/fund-rejection-email-2.jpg 1242w"],
          "sizes": "(max-width: 1242px) 100vw, 1242px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1242px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "216.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGgAAAgMBAQAAAAAAAAAAAAAAAAECBAUDBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHLUmQEHoKNDnLomeWJT6lcA//EAB8QAQABBAEFAAAAAAAAAAAAAAECABAREgMEEyAhMf/aAAgBAQABBQK2btZe1wepdQG7OSZa2Xwj9lECv//EABYRAAMAAAAAAAAAAAAAAAAAABARIP/aAAgBAwEBPwEOP//EABYRAAMAAAAAAAAAAAAAAAAAABAgIf/aAAgBAgEBPwEVP//EAB4QAAEEAQUAAAAAAAAAAAAAAAABEBExISAyQmGB/9oACAEBAAY/Ano2theIqSveTwiSy9f/xAAgEAEAAgEDBQEAAAAAAAAAAAABABExECGRQVFhgfCh/9oACAEBAAE/IVl/XHdA+kRMriBvCeoGChw4j2i8uqUNfqWg67XFMqOQmVK0FkZcQ0//2gAMAwEAAgADAAAAEGghT8vv/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARAf/aAAgBAwEBPxBQbH//xAAYEQACAwAAAAAAAAAAAAAAAAABEAAhUf/aAAgBAgEBPxBAalv/xAAfEAEAAwACAgMBAAAAAAAAAAABABEhMUEQUWFxgZH/2gAIAQEAAT8QwDo40gK436QVYy0KTn6nCC/ce5nTSHMECwprWy+jA7zlnfNwm5sLbdt9sGKkqyRPmWtkdbZVZRRbeRzlv4yxyP8APAXxF3YxoNnb4//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fund Rejection Email 3",
          "title": "Fund Rejection Email 3",
          "src": "/static/2c3ddc430dada55a64195e619a8c321c/ff5f8/fund-rejection-email-3.jpg",
          "srcSet": ["/static/2c3ddc430dada55a64195e619a8c321c/a6407/fund-rejection-email-3.jpg 400w", "/static/2c3ddc430dada55a64195e619a8c321c/a24e6/fund-rejection-email-3.jpg 800w", "/static/2c3ddc430dada55a64195e619a8c321c/ff5f8/fund-rejection-email-3.jpg 1242w"],
          "sizes": "(max-width: 1242px) 100vw, 1242px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`No problem. Tally one up for the no column. Attempt #2 - He shoots….he scores!!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABcUlEQVQY02WPXU7CUBCFuylfTUz8i4YNmLgFl+ECXIMvxhcVNFEkGg0SgQKlttBigbaWglSEtrT33rZ3vICiiSeTmWQm3zkZbuRYLd0q1qRyvVEW5JIg84JcERVebLJlVVIrYoOdbOczSZJ4riiKAKDT6XAtOS9rb9d3+Zv7p+xD4fbxOZsv5vKlQkUq1mT+ReVFtSg0bWfCAErpshuGwWmvat8Zt027bdia0TN7g9pzUx+MXD/wQuJOsRdgN8ABIvCjBazrOsdGiBAmTBGJ48nQfc+J8E8JBZ9Qb14uTsJvmK3jZOnX90kYkoX/EmM6qqOVUzd16W9deDtpb/sKHZyrHJAYZGNWUwRCG6oa9EZ/MxcmghMf8mgr7a+deetn7moa750o3Cy2OwDNBvbVa48qFgzGYH3A4kn6G1534s0Lf/fST2W8jQzaP2XwFINigqRDgKHdh4YJyhu0LMDREp7xADGFEaLDkDoe6R5nhKr0BUbmr7SzSKk9AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fund Hot Lead Email",
          "title": "Fund Hot Lead Email",
          "src": "/static/eb77e1d1b112ed436eb3cafc9a0871ac/0f09e/fund-hot-lead-email.png",
          "srcSet": ["/static/eb77e1d1b112ed436eb3cafc9a0871ac/d9f49/fund-hot-lead-email.png 400w", "/static/eb77e1d1b112ed436eb3cafc9a0871ac/0f09e/fund-hot-lead-email.png 640w"],
          "sizes": "(max-width: 640px) 100vw, 640px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "847px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "23.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfUlEQVQY05WQsRIDIQhE/f8fzHWXS6eop2C0EL2AaZPiXrHADjvDYJDosW3H8eq9XzcxrbV9f54pXfcxY4y0oFJyzmWBiKRKmFFM1BnftWolwoVEDDOnU4kxhhAA4Kvee3FUww/Ag+Q1LKvWWtlzztVa5ZbOLC+Qhv8z5/wA7FYg0vjJkOcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fund Hot Lead Email 2",
          "title": "Fund Hot Lead Email 2",
          "src": "/static/b4824545ffb329ad18c400e6309ce697/f27d8/fund-hot-lead-email-2.png",
          "srcSet": ["/static/b4824545ffb329ad18c400e6309ce697/d9f49/fund-hot-lead-email-2.png 400w", "/static/b4824545ffb329ad18c400e6309ce697/78d47/fund-hot-lead-email-2.png 800w", "/static/b4824545ffb329ad18c400e6309ce697/f27d8/fund-hot-lead-email-2.png 847w"],
          "sizes": "(max-width: 847px) 100vw, 847px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Boom!
$100k on the board! Now we can’t get too cocky. Hot leads are always the easiest, but they run out quick (I only have 19). And this close ($100k)
is 3x bigger than what I think the average will be. Still, good time for a mini
celebration. (I jumped in the pool to celebrate the first check. It was
freezing, but who cares).`}</p>
    <p><strong parentName="p">{`End of the day:`}</strong></p>
    <ul>
      <li parentName="ul">{`Yes's: 4`}</li>
      <li parentName="ul">{`No's: 4`}</li>
      <li parentName="ul">{`Maybe: 6`}</li>
      <li parentName="ul">{`No Reply: 5`}</li>
      <li parentName="ul">{`Total Raised: $190k`}</li>
      <li parentName="ul">{`% of the way to my goal: 19%`}</li>
    </ul>
    <p><strong parentName="p">{`Today was about "dealing with rejection". Fear of rejection is a silent
killer. It strangles many founder's willingness to go sell their product. We
won't let that happen! Today we did the easy bit. Danced with fear, and pitched
our hot leads.`}</strong></p>
    <p>{`Tomorrow things get trickier. Tomorrow we start pitching "warm leads". Aka
people I know, but who didn't say they wanted to invest in the fund. This will
be much harder, much more rejection coming, and much more skill required.`}</p>
    <p>{`Talk soon,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/1uhOejITxiIIvUsYRgY3SqPbpS1UUZ8XT9-exIn28hzE/edit?usp=sharing',
        label: 'Rejection Calculator'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.nailing_our_pitch.label,
      slug: slugify(postMap.nailing_our_pitch.slug)
    }} nextButton={{
      text: postMap.going_down_the_funnel.label,
      slug: slugify(postMap.going_down_the_funnel.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      